import SearchIcon from "@material-ui/icons/Search";
import Product from "./Product/Product.js";
import useStyles from "./styles";
import Carousel from "react-bootstrap/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo1 from "../../assets/6.jpg";
import logo2 from "../../assets/4.jpg";
import loader1 from "../../assets/vdd.png";
import "../ProductView/style.css";
import "./Products.css";
import { Carousel as RCaorusel } from "react-responsive-carousel";
import { Col, Row } from "react-bootstrap";
import axios from "axios"; // Import axios
import { Typography, useMediaQuery } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom/cjs/react-router-dom.js";
import { database } from "../Google_login/config.js";
import { collection, getDocs } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Products = ({ onAddToCart }) => {
  const classes = useStyles();

  const isMobile = useMediaQuery("(max-width:600px)");
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState("");
  const db = collection(database, "Carousal Images");

  const [cImages, setCImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  var tempArray = [];
  var secondary = [];

  

  useEffect(() => {
    const getData = async () => {
      const cImageDoc = await getDocs(db);
      tempArray = cImageDoc.docs.map((doc) => ({ ...doc.data() }));
      tempArray.map((tempData) => {
        if (tempData.isActive) {
          secondary.push(tempData.imageUrl);
        }
      });
      setCImages(secondary);
    };
    getData();
  }, []);


  const location = useLocation();

 

  const formatMonth = (date) => {
    const month = new Date(date).toLocaleDateString("en-US", {
      month: "short",
    });
    return month.toUpperCase(); // Convert to uppercase if needed
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % cImages.length);
    }, 2000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [4]);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", { day: "numeric" });
  };

  const handleSwipe = (index) => {
    setCurrentImage(images[index]);
    setIndex(index);
  };
  const handleDotClick = (dotIndex) => {
    setIndex(dotIndex);
    setCurrentImage(images[dotIndex]);
  };

  const handleMultipleImages = (image, event) => {
    event.preventDefault();
    setCurrentImage(image);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]); // State to store products

  useEffect(() => {
    // Fetch products when the component mounts
    axios
      .get("https://fourband-prod-on2dz.ondigitalocean.app/v1/data/events-list/")
      .then((response) => {
        console.log("Total Events...", response.data.data.results);
        setProducts(response.data.data.results);
        setImages(response.data.data.results);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after fetch is complete
      });
  }, []);

  const filteredProducts = Array.isArray(products)
    ? products.filter((product) => {
        if (searchTerm === "") {
          return true;
        } else if (product.title && typeof product.title === "string") {
          return product.title.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false; // Skip products with undefined or non-string name property
      })
    : [];

  const pairedProducts = [];
  for (let i = 0; i < filteredProducts.length; i += 2) {
    pairedProducts.push(filteredProducts.slice(i, i + 2));
  }




  

  // loader 

  const renderLoader = () => (
    <div className="make_center loader">
      <div className="spinner">
        <img src={loader1} alt="Loading" />
      </div>
    </div>
  );
  
  return (
    <main style={isMobile ? { overflowX: "hidden" } : { overflow: "hidden" }}>
      <div
        style={{ marginTop: "5px" }}
        className={`${classes.toolbar} carousel_div`}
      />
      {!isMobile && (
        <Carousel interval={3000} fade indicators={false} className="image_div">
          {cImages.map((url, index) => (
            <Carousel.Item key={index}>
              <div
                className="carousel_div"
                style={{ backgroundColor: "black" }}
              >
                <img
                  className="d-block w-100 search_cust_img"
                  src={url}
                  alt={`slide ${index}`}
                  style={{ padding: "20px", paddingTop: "20px" }}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      )}

      {!isMobile && (
        <>
          <div className="upcoming-banner upcoming-banner-width">
            <p style={{ color: "white" }}>Upcoming Event</p>
          </div>
          <br />
        </>
      )}

      {/* {isMobile && (
        <div style={{ background: "black", height: "100vh" }}> */}
          {/* <div className="mobile_search" style={{ background: "red" }}>
            <input
              className="cust_search"
              type="text"
              placeholder="Search ..."
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </div> */}

{/* <SwipeableViews
  style={{ backgroundColor: "#1A1A1A" }}
  index={index}
  onChangeIndex={setIndex}
>
  {cImages.map((url, index) => (
    <div style={{ padding: "10px" }} key={index}>
      <img
        className="d-block w-100 search_cust_img square-image"
        src={url}
        alt={`slide ${index}`}
        style={{ borderRadius: "10px" }}
      />
    </div>
  ))}
</SwipeableViews> */}
{/* newcode */}
{isMobile && (
  <div style={{ 
    background: "black", 
    width: "100%", 
    maxWidth: "100vw",
    margin: 0,
    padding: 0
  }}>
    <SwipeableViews
      style={{ 
        width: "100%",
        padding: 0,
        margin: 0
      }}
      index={index}
      onChangeIndex={setIndex}
    >
      {cImages.map((url, idx) => (
        <div 
          key={idx} 
          style={{ 
            width: "100%",
            margin: 0,
            padding: 0
          }}
        >
          <div style={{
            width: "100vw",
            height: "320px", // Fixed height for mobile
            position: "relative",
            overflow: "hidden"
          }}>
            <img
              src={url}
              alt={`slide ${idx}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "block"
              }}
            />
          </div>
        </div>
      ))}
    </SwipeableViews>

    <div className="upcoming-banner upcoming-banner-mobile">
      <p style={{ color: "white" }}>Upcoming Events</p>
    </div>

{/* 
          <div className="upcoming-banner upcoming-banner-mobile">
            <p style={{ color: "white" }}>Upcoming Events</p>
          </div> */}


          {/* The Grid Commented Below Was Placed Here */}
          {/* {products[0] ? (
            pairedProducts.map((productPair, index) =>
              isMobile ? (
                <>
                  <Row style={{ marginLeft: "20px", marginRight: "20px", paddingBottom: "30px" }}>
                    {productPair.map((final_products) => (
                      <Col
                        key={final_products.id}
                        xs={12}
                        sm={6}
                        style={{ marginTop: "20px" }}
                      >
                        <Product
                          product={final_products}
                          onAddToCart={onAddToCart}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              ) : (
                <></>
              )
            )
          ) : (
            <div className="make_center loader">
              <div className="spinner">
                <img src={loader1} alt="Loading" />
              </div>
            </div>
          )}
        </div>
      )} */}
      {/* //new code after fixing loader  */}

      {isLoading ? (
        <div className="make_center loader">
          <div className="spinner">
            <img src={loader1} alt="Loading" />
          </div>
        </div>
      ) : products.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
            color: "white",
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          No Upcoming Events in Your Circle !
        </div>
      ) : (
        pairedProducts.map((productPair, index) => (
          <Row
            key={index}
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              paddingBottom: "30px",
            }}
          >
            {productPair.map((final_products) => (
              <Col
                key={final_products.id}
                xs={12}
                sm={6}
                style={{ marginTop: "20px" }}
              >
                <Product product={final_products} onAddToCart={onAddToCart} />
              </Col>
            ))}
          </Row>
        ))
      )}
    </div>
  )}
    

      {/* {products[0] ? (
        pairedProducts.map((productPair, index) =>
          !isMobile ? (
            <Row style={{ marginLeft: "20px", marginRight: "20px" }}>
              {productPair.map((final_products) => (
                <Col
                  key={final_products.id}
                  xs={12}
                  sm={6}
                  style={{ marginTop: "50px" }}
                >
                  <Product product={final_products} onAddToCart={onAddToCart} />
                </Col>
              ))}
            </Row>
          ) : (
            <></>
          )
        )
      ) : (
        <div className="make_center loader">
          <div className="spinner">
            <img src={loader1} alt="Loading" />
          </div>
        </div>
      )} */}

{!isMobile &&
    (isLoading ? (
      <div className="make_center loader">
        <div className="spinner">
          <img src={loader1} alt="Loading" />
        </div>
      </div>
    ) : products.length === 0 ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
          color: "white",
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        No Upcoming Events in Your Circle !
      </div>
    ) : (
      pairedProducts.map((productPair, index) => (
        <Row key={index} style={{ marginLeft: "20px", marginRight: "20px" }}>
          {productPair.map((final_products) => (
            <Col
              key={final_products.id}
              xs={12}
              sm={6}
              style={{ marginTop: "50px" }}
            >
              <Product product={final_products} onAddToCart={onAddToCart} />
            </Col>
          ))}
        </Row>
      ))
    ))}
    </main>
  );
};

export default Products;

// <Grid item xs={12} className="image-wrapper">
//             <SwipeableViews
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 width: "100%",
//                 background: "black",
//                 padding: "20px",
//                 flexDirection: "column",
//                 height: "100vh",
//                 overflow: "hidden",
//                 gap: "20px",
//               }}
//               enableMouseEvents
//               index={index}
//               onChangeIndex={handleSwipe}
//             >
//               {filteredProducts.map((product, index) => (
//                 <div
//                   style={{
//                     display: "flex",
//                     width: "90vw",
//                     height: "80vh",
//                     overflow: "hidden",
//                     position: "relative",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Link
//                     to={`product-view/${product.id}`}
//                     key={index}
//                     style={{ textDecoration: "none" }}
//                   >
//                     <img
//                       key={index}
//                       style={{
//                         width: "80vw",
//                         borderRadius: "20px",
//                         height: "90%",
//                         boxShadow:
//                           "rgb(59 39 39) 1px 1px 1px, -1px -1px 1px #e3e3e3",
//                         marginTop: "5px",
//                       }}
//                       src={product.image}
//                       alt={product.title}
//                     />
//                   </Link>
//                   <Typography
//                     style={{
//                       textTransform: "uppercase",
//                       textDecoration: "none",
//                       color: "#e3e3e3",
//                       position: "absolute",
//                       top: "10px",
//                       left: "40px",
//                       fontSize: "13px",
//                     }}
//                     color="white"
//                     variant="h6"
//                     align="center"
//                   >
//                     FEATURED
//                   </Typography>
//                   <Typography
//                     style={{
//                       textTransform: "uppercase",
//                       textDecoration: "none",
//                       color: "#e3e3e3",
//                       position: "absolute",
//                       top: "10px",
//                       right: "40px",
//                       fontSize: "12px",
//                     }}
//                     color="white"
//                     variant="h6"
//                     align="center"
//                   >
//                     {formatMonth(product.date)}
//                   </Typography>
//                   <Typography
//                     style={{
//                       textTransform: "uppercase",
//                       textDecoration: "none",
//                       color: "#e3e3e3",
//                       position: "absolute",
//                       top: "25px",
//                       right: "40px",
//                       fontSize: "20px",
//                       fontWeight: "bold",
//                     }}
//                     color="white"
//                     variant="h6"
//                     align="center"
//                   >
//                     {formatDate(product.date)}
//                   </Typography>
//                   <Typography
//                     style={{
//                       textTransform: "uppercase",
//                       textDecoration: "none",
//                       color: "#e3e3e3",
//                       position: "absolute",
//                       bottom: "110px",
//                       left: "40px",
//                       fontSize: "13px",
//                     }}
//                     color="white"
//                     variant="h6"
//                     align="center"
//                   >
//                     CONCERT
//                   </Typography>
//                   <Typography
//                     style={{
//                       textTransform: "uppercase",
//                       textDecoration: "none",
//                       color: "white",
//                       fontWeight: "bold",
//                       position: "absolute",
//                       bottom: "80px",
//                       left: "40px",
//                       whiteSpace: "nowrap",
//                       maxWidth: "350px",
//                       textOverflow: "ellipsis",
//                       overflow: "hidden",
//                     }}
//                     color="white"
//                     variant="h5"
//                     align="center"
//                   >
//                     {product.title}
//                   </Typography>
//                 </div>
//               ))}
//               {filteredProducts.length === 0 && (
//                 <div
//                   style={{
//                     display: "flex",
//                     width: "90vw",
//                     height: "80vh",
//                     overflow: "hidden",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   {/* <Typography variant='subtitle1' style={{color:"red"}}>No results for "{searchTerm}"</Typography> */}
//                 </div>
//               )}
//             </SwipeableViews>

//             <div className="dots">
//               {images.map((_, dotIndex) => (
//                 <div
//                   key={dotIndex}
//                   className={`dot ${index === dotIndex ? "active" : ""}`}
//                   onClick={() => setIndex(dotIndex)}
//                 />
//               ))}
//             </div>
//           </Grid>
