import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  CardActionArea,
  Snackbar
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Alert } from "@mui/material";

const Product = ({ product, onAddToCart }) => {
  
  const history = useHistory();
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleBookingClick = (e) => {
    e.preventDefault(); // Prevent default navigation
    e.stopPropagation(); // Stop event from propagating to CardActionArea

    if (product.pause_booking) {
      setOpenSnackbar(true);
    } else {
      history.push(`product-view/${product.id}`);
    }
  };

  const handleCardClick = (e) => {
    e.preventDefault(); // Prevent default navigation

    if (product.pause_booking) {
      setOpenSnackbar(true);
    } else {
      history.push(`product-view/${product.id}`);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
    {/* <Link to={`product-view/${product.id}`} style={{textDecoration: 'none'}}> */}
      <Card className={classes.root}>
        <CardActionArea onClick={handleCardClick}>
          <CardMedia
            // className={classes.media}
             className="fixed-image-container"
            image={product.image}
            title={product.address}
          />
        </CardActionArea>

        <CardContent>
          <div className={classes.cardContent}>
            <Typography variant="h5" style={{ fontFamily: "Verdana" }}>
              {product.title}
            </Typography>
          </div>
          <br></br>
          <div className={classes.cardContent}>
            <Typography
              style={{ fontFamily: "Verdana", color: "red", fontSize: "15px" }}
            >
              {/* <b>{product.ticket_types.pricing[0].price} Onwards</b> */}
            </Typography>
          </div>
        </CardContent>
        {/* <CardActions disableSpacing className={classes.cardActions}> */}
          {/* {product?.available_tickets > 1 ?
        (
          
          ):
            (
              <><p style={{textAlign:"center", color:"black", background:"yellow", padding:"2px 4px", borderRadius:"4px"}}>Tickets Sold Out!</p>  </>
            )
            } */}
          {/* <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to={`product-view/${product.id}`}
          >
            <b>BOOK NOW</b>
          </Button> */}
             <Button
          variant="contained"
          className={classes.button}
          onClick={handleBookingClick}
        >
          <b>BOOK NOW</b>
        </Button>
        {/* </CardActions> */}
      </Card>
    {/* </Link> */}
 
        <Snackbar 
        open={openSnackbar} 
        autoHideDuration={4000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity="info"
          style={{
            backgroundColor: 'black',
            color: 'white',
            width: '100%',
            textAlign: 'center'
          }}
        >
          Booking is paused for now, please try again later
        </Alert>
      </Snackbar>
      </>
  );
};

export default Product;
