import React, { useState } from "react";
import { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Link, useHistory,useLocation } from "react-router-dom";
import logo from "../../assets/circles.png";
import bookingIcon from "../../assets/booking-forshow.svg";
import logoutIcon from "../../assets/logout.svg";
import loginIcon from "../../assets/login-forshow.svg"; // Importing images
import useStyles from "./styles";
import axios from "axios";
import { auth, provider } from "../Google_login/config";
import { signInWithPopup } from "firebase/auth";
import { useMediaQuery, useTheme } from "@material-ui/core";

const Navbar = ({ totalItems }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(localStorage.getItem("dummy_token2")));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

   // Function to check authentication and show alert
   const checkAuthAndRedirect = () => {
    const token = localStorage.getItem("dummy_token2");
    if (!token && location.pathname !== "/") {
      alert("Please login first to continue");
      history.push("/");
      return false;
    }
    return true;
  };

  // Add effect to check token on route change
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname !== "/" && !localStorage.getItem("dummy_token2")) {
        alert("Please login first to continue");
        history.push("/");
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const checkSessionTimeout = () => {
      const token = localStorage.getItem("dummy_token2");
      if (!token) {
        setIsAuthenticated(false);
        // Only redirect and show alert if not on home page
        if (location.pathname !== "/") {
          alert("Session expired. Please login again.");
          history.push("/");
        }
      }
    };

    const interval = setInterval(() => {
      checkSessionTimeout();
    }, 5000);

    return () => clearInterval(interval);
  }, [history, location.pathname]);

  // //added for expiry  check 
  // useEffect(() => {
  //   // Function to check token expiration
  //   const checkSessionTimeout = () => {
  //     const token = localStorage.getItem("dummy_token2");
  //     if (!token) {
  //       setIsAuthenticated(false);
  //       history.push("/"); // Redirect to home page when token is removed
  //     }
  //   };

  //   // Set an interval to check for session expiration
  //   const interval = setInterval(() => {
  //     checkSessionTimeout();
  //   }, 5000); // Check every 5 seconds

  //   return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, [history]);

  


  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(Boolean(localStorage.getItem("dummy_token2")));
    };
  
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);


  const generateRandomDeviceId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let deviceId = '';
  
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      deviceId += characters.charAt(randomIndex);
    }
  
    return deviceId;
  };

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const { email, uid } = result.user;
      const deviceId = generateRandomDeviceId();

      // Send the ID token and additional data to the API
      const response = await axios.post(
        'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/auth/login/',
        { email, device_id: deviceId, uid }
      );

      if ((response.status === 200 && response.data.message === 'User Login success') || (response.status === 201 && response.data.message === 'User added successfully')) {
        const token = response.data.data.token;
        localStorage.setItem('dummy_token2', token);
        setIsAuthenticated(true);
        history.push(`/`); // Redirect to home page after successful login
      } else {
        console.error('Failed to send credentials to API. Status:', response.status);
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  const handleLogout = async () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (!confirmed) return; // Exit if the user clicks "Cancel"

    const token = localStorage.getItem('dummy_token2');
    const headers = {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      // Call the logout API
      await axios.post(
        'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/auth/logout/',
        {},
        { headers }
      );

      // Clear token from localStorage and update state
      localStorage.removeItem('dummy_token2');
      setIsAuthenticated(false);
      history.push(`/`); // Redirect to home page
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          <Typography
            component={Link}
            to="/"
            variant="h5"
            className={classes.title}
            color="inherit"
          >
            <img
              src={logo}
              alt="Book Store App"
              height="50px"
              className={classes.image}
            />
          </Typography>

          <div className={classes.grow} />

          {/* <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>

            {isAuthenticated ? (
              <>
                {/* <Button
                  component={Link}
                  to="/booking"
                  color="inherit"
                  style={{
                    fontSize: isMobile ? '0.9rem' : '1.1rem',
                    padding: isMobile ? '5px 10px' : '10px 20px',
                    marginRight: '10px',
                    textTransform: 'none', // Prevents text capitalization
                  }}
                >
                  Bookings
                  <img
                    src={bookingIcon}
                    alt="Bookings Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                  />
                </Button> */}
                {/* <Button
  onClick={handleLogout}
  color="inherit"
  style={{
    fontSize: isMobile ? '0.9rem' : '1.1rem',
    padding: isMobile ? '5px 10px' : '10px 20px',
    marginRight: isMobile ? '5px' : '10px', // Reduce margin for small screens
    textTransform: 'none',
    display: isMobile ? 'block' : 'inline-flex', // Ensure visibility
  }}
>

                  Logout
                  <img
                    src={logoutIcon}
                    alt="Logout Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                  />
                </Button> */}



{/* <Button
                  component={Link}
                  to="/booking"
                  color="inherit"
                  style={{
                    fontSize: isMobile ? '0.9rem' : '1.1rem',
                    padding: isMobile ? '5px 10px' : '10px 20px',
                    marginRight: isMobile ? '0' : '10px',
                    textTransform: 'none',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Bookings
                  <img
                    src={bookingIcon}
                    alt="Bookings Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                  />
                </Button>
                <Button
                  onClick={handleLogout}
                  color="inherit"
                  style={{
                    fontSize: isMobile ? '0.9rem' : '1.1rem',
                    padding: isMobile ? '5px 10px' : '10px 20px',
                    marginRight: isMobile ? '0' : '10px',
                    textTransform: 'none',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Logout
                  <img
                    src={logoutIcon}
                    alt="Logout Icon"
                    style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                  />
                </Button>
            */}

{isMobile ? (
                  <>
                  <Tooltip title="Bookings" arrow>
                      <IconButton component={Link} to="/booking" color="inherit">
                        <img src={bookingIcon} alt="Bookings Icon" style={{ width: '24px', height: '24px' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Logout" arrow>
                      <IconButton onClick={handleLogout} color="inherit">
                        <img src={logoutIcon} alt="Logout Icon" style={{ width: '24px', height: '24px' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Button
                      component={Link}
                      to="/booking"
                      color="inherit"
                      style={{ fontSize: '1.1rem', padding: '10px 20px', marginRight: '10px', textTransform: 'none', display: 'flex', alignItems: 'center' }}
                    >
                      Bookings
                      <img src={bookingIcon} alt="Bookings Icon" style={{ width: '20px', height: '20px', marginLeft: '5px' }} />
                    </Button>
                    <Button
                      onClick={handleLogout}
                      color="inherit"
                      style={{ fontSize: '1.1rem', padding: '10px 20px', marginRight: '10px', textTransform: 'none', display: 'flex', alignItems: 'center' }}
                    >
                      Logout
                      <img src={logoutIcon} alt="Logout Icon" style={{ width: '20px', height: '20px', marginLeft: '5px' }} />
                    </Button>
                  </>
                )}

              </>
            ) : (
              <Button
                onClick={handleLogin}
                color="inherit"
                style={{
                  fontSize: isMobile ? '0.9rem' : '1.2rem',
                  padding: isMobile ? '5px 10px' : '10px 20px',
                  marginRight: '10px',
                  textTransform: 'none', // Prevents text capitalization
                }}
              >
                Login
                <img
                  src={loginIcon}
                  alt="Login Icon"
                  style={{ width: '26px', height: '26px', marginLeft: '5px' }}
                />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
